import React from "react";
import quoteIcon from "../img/quote_icon.png";
import "../css/Aside.css";

const Aside = ({ champion }) => {
  return (
    <div className="aside">
      <div className="aside-nav">
        {/* <ul>
          <li>Abilities</li>
          <li>Skin</li>
          <li>Background</li>
        </ul> */}
      </div>
      <div className="aside-section">
        <div className="phrase">
          <sup>
            <img src={quoteIcon} alt="Quote icon" />
          </sup>
          <span>{champion.blurb}</span>
          <sup>
            <img src={quoteIcon} alt="Quote icon" />
          </sup>
        </div>
        <div
          className="video"
          style={{
            backgroundImage: `url('http://ddragon.leagueoflegends.com/cdn/11.5.1/img/champion/${champion.id}.png')`,
            backgroundPosition: `center`,
            backgroundSize: `cover`,
            backgroundRepeat: `no-repeat`,
            backgroundBlendMode: "multiply",
          }}
        ></div>
        <div className="download">
          <a
            href="https://signup.leagueoflegends.com/pt-br/signup/redownload"
            target="_blank"
            rel="noreferrer"
          >
            <button>Baixe o League of Legends</button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Aside;
