import React, { useState } from "react";
import Navigator from "./Navigator";
import "../css/Hero.css";

const Hero = ({ champion }) => {
  const [selectedSkin, setSelectedSkin] = useState(champion.skins[0]);

  const handleSkinClick = (skin) => {
    setSelectedSkin(skin);
  };

  return (
    <div
      className="hero-background"
      style={{
        backgroundImage: `url(http://ddragon.leagueoflegends.com/cdn/img/champion/splash/${champion.id}_${selectedSkin.num}.jpg)`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundBlendMode: "multiply",
      }}
    >
      <div>
        <Navigator />
      </div>
      <div className="hero-content">
        <div className="champion-screen-selector">
          {champion.skins.map((skin) => (
            <div
              key={skin.id}
              className={`screen-selector-item ${
                selectedSkin.num === skin.num ? "active" : ""
              }`}
              onClick={() => handleSkinClick(skin)}
            >
              <span>{skin.name}</span>
            </div>
          ))}
        </div>
        <div className="champion-content">
          <div className="champion-title">{champion.name}</div>
          <div className="champion-phrase">{champion.title}</div>
        </div>
        <div className="blank"></div>
      </div>
    </div>
  );
};

export default Hero;
