import axios from "axios";
import { useEffect, useState } from "react";
import "./css/App.css";
import Aside from "./Components/Aside";
import Hero from "./Components/Hero";

function App() {
  const [champion, setChampion] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [championName, setChampionName] = useState("Yasuo");
  const [championsList, setChampionsList] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        "https://ddragon.leagueoflegends.com/cdn/13.8.1/data/pt_BR/champion.json"
      )
      .then((response) => {
        setChampionsList(Object.keys(response.data.data));
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        `https://ddragon.leagueoflegends.com/cdn/13.8.1/data/pt_BR/champion/${championName}.json`
      )
      .then((response) => {
        setChampion(response.data.data[championName]);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  }, [championName]);

  const handleChampionChange = (event) => {
    setChampionName(event.target.value);
  };

  return (
    <>
      {isLoading ? (
        <div>Carregando...</div>
      ) : (
        <div className="App">
          <div>
            <Hero champion={champion} />

            <div className="search">
              <select value={championName} onChange={handleChampionChange}>
                {championsList.map((name) => (
                  <option key={name} value={name}>
                    {name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div>{champion ? <Aside champion={champion} /> : null}</div>
        </div>
      )}
    </>
  );
}

export default App;
