import React from "react";

import logo from "../img/lol_logo.png";

import "../css/Navigator.css";

const Navigator = () => {
  return (
    <div className="navigator">
      <div className="navigator-logo">
        <img src={logo} alt="Logo image" />
        <span> League of Legends</span>
      </div>
      {/* <div className="nav-menu">
        <div className="trace"></div>
        <div className="trace"></div>
        <div className="trace"></div>
      </div> */}
    </div>
  );
};

export default Navigator;
